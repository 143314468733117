export const state = {
  logoInfo: {},
  canvasLoading: false,
  svgArray: [],
  changeIconList: [],

  letterList: [],
  searchText: "",

  //edit
  fontList: [],
  containerList: [],
  IconList: [],
};
